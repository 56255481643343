<template>
  <section class="container">
    <app-bar title="Monitoring Pribadi" previousLink="/hasil" />
    <Loading v-if="loading.monitoring" />
    <Empty v-else-if="monitoring.detail.length === 0" :message="'Saat ini belum ada monitoring untuk ditampilkan.'" />
    <div v-else class="row">
      <div class="col-12 col-md-12 p-0">
        <div class="table-responsive">
          <table class="table table-monitoring table-striped w-100">
            <thead class="fs-12 lh-22 fw-700 color-neutral-700 bg-neutral-200 text-center">
              <tr v-for="header in monitoring.header" :key="header">
                <th scope="col" v-for="(value, key) in header" :key="value" class="text-center align-middle">
                  <div :style="{ width: (key === 'th_namapemeriksaan' ? '130px' : 'min-content') }" class="mx-auto">{{ value
                  }}</div>
                </th>
              </tr>
            </thead>
            <tbody class="fs-12 lh-22 color-neutral-700">
              <tr v-for="detail in monitoring.detail" :key="detail">
                <td v-for="(value, key) in detail" :key="key">
                  <div :class="{ 'text-center': key !== 'th_namapemeriksaan' }" style="word-break: break-word;"
                    :style="{ width: (key === 'th_namapemeriksaan' ? '130px' : '200px') }">
                    {{ value }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Loading from '@/components/utility/Loading'
import Empty from '@/components/utility/Empty'
import { onMounted } from 'vue'
import { getMonitoring, loading, monitoring } from '@/core/hasil'
import { user } from '@/core/auth'
import { showNotif } from '@/core/utility'
import AppBar from '@/components/AppBar.vue'

export default {
  components: { Loading, Empty, AppBar },
  setup () {
    const prepareGetMonitoring = async () => {
      try {
        const result = await getMonitoring({
          pas_id: user.value.pas_id
        })

        if (!result.status) {
          showNotif({
            status: 'error',
            message: status.message
          })
        }
      } catch (error) {
        console.error('error dari Monitoring prepareGetMonitoring', error)
      }
    }

    onMounted(async () => {
      await prepareGetMonitoring()
    })

    return {
      loading,
      monitoring
    }
  }
}
</script>

<style lang="scss" scoped>
#patient-monitoring {
  margin-top: 60px;
}

.card-patient {
  .doctor-info {
    border-radius: 11px;
  }

  .icon-wrapper {
    img {
      width: 14px;
      height: 14px;
    }
  }
}

.table-responsive {
  overflow-x: auto;

  .table-monitoring {

    th:first-child,
    td:first-child {
      position: sticky;
      left: 0px;
      padding-left: 20px;
    }

    th:first-child {
      background-color: #edf2f7;
    }

    td:first-child {
      background-color: #f7fafc;
    }
  }

  .table-monitoring> :not(caption)>*>* {
    border-bottom-width: 0px !important;
  }
}
</style>
